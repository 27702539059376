<template>
  <v-parallax src="@/assets/images/pages/password-bg.png" height="100%">
    <v-container fill-height>
      <v-row align="center" justify="center">
        <v-col align-self="center" cols="12" sm="6">
          <Logo/>
        </v-col>
        <v-col cols="12" sm="4">
          <v-card outlined class="pa-3" height="500">
            <v-card-text class="pt-3">
              <h2 class="mb-10">Enter your password</h2>
              <v-form class="mt-4" ref="loginForm" @submit.prevent="signinSubmitClick()">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Password"
                      placeholder="Password"
                      autocomplete="off"
                      v-model="loginForm.password"
                      :type="showPassword ? 'text' : 'password'"
                      :append-icon="showPassword ? icons.mdiEye : icons.mdiEyeOff"
                      :rules="[required('Password')]"
                      @click:append="showPassword = !showPassword"
                      @focus="handlePass"
                    ></v-text-field>
                    <v-btn
                      absolute
                      right
                      small
                      class="my-3 mx-3 rounded-pill"
                      color="primary"
                      type="submit"
                      :disabled="(loginForm.email=='' || loginForm.password=='') ? true : false"
                      :loading="loading"
                    >Continue</v-btn>
                  </v-col>
                </v-row>
                <v-row class="pt-3">
                  <v-col cols="12">
                    <v-switch
                      v-model="loginForm.stay"
                      text-color="red"
                      label="Stay signed in"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-form>
              <div class="mt-15">
                <v-divider></v-divider>
                <div class="pt-3" align-self="center">
                  <router-link to="/forgot-password">
                    Forgot your Password?
                  </router-link>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-parallax>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { LOGIN, LOGOUT, LOAD_ORGANIZATION, ADD_ALERT } from "@/store/_actiontypes";
import validations from "@/utils/validations";
import Logo from "@/components/common/Logo.vue"
import { mdiEye, mdiEyeOff } from '@mdi/js'
import * as firebaseService from '@/database/firebase'

export default {
  components: {
    Logo
  },
  data(){
    return {
      showModal: false,
      icons: {
        mdiEye,
        mdiEyeOff,
      },
      loginForm: {
        email: "",
        password: "",
      },
      showPassword: false,
      passType: true,
      ...validations,
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.loader.loading,
      login_error: (state) => state.account.login_error,
      user: (state) => state.account.user,
      organization: (state) => state.account.organization,
    }),
  },
  created(){
    if(!this.$route.query.email){
      this.$router.push({path: '/signin'});
    }
    this.loginForm.email = this.$route.query.email;
    this.$vuetify.theme.dark = this.$tools.getLocal('settings').theme.int;
  },
  mounted(){
    if(this.$tools.getEnv("VUE_APP_CAPTCHA_ENABLED")){
      const plugin = document.createElement("script");
      plugin.setAttribute(
        "src",
        `https://www.google.com/recaptcha/api.js?render=${this.$tools.getEnv("VUE_APP_CAPTCHA_SITE_KEY")}`
      );
      plugin.async = true;
      document.head.appendChild(plugin);
    }
  },
  methods: {
    ...mapActions("account", [LOGIN, LOGOUT, LOAD_ORGANIZATION]),
    ...mapActions("alert", [ADD_ALERT]),

    signinSubmitClick(){
      if(!this.$refs.loginForm.validate()) return;

      if(this.$tools.getEnv("VUE_APP_CAPTCHA_ENABLED")){
        grecaptcha.execute(this.$tools.getEnv("VUE_APP_CAPTCHA_SITE_KEY"), {
          action: 'login'
        }).then((recaptcha_token) => {
          this.handleLoginSubmit(recaptcha_token);
        });
      } else{
        this.handleLoginSubmit(null);
      }
    },
    handleLoginSubmit(recaptcha_token){
      const { email, password } = this.loginForm;
      if(email && password){
        this.LOGIN({ email, password, recaptcha_token }).then((response) => {
          if(response.status == 200){
            const parsedJWTJson = this.$tools.parseJwt(this.user.access_token);
            if(parsedJWTJson["ust"] === "inactive" || parsedJWTJson["ast"] === "inactive"){
              this.$store.dispatch(`alert/${ADD_ALERT}`,
                {
                  message: "Currently your account is not active.",
                  color: "error",
                },
                { root: true }
              );
            } else{
              if(this.user){
                this.LOAD_ORGANIZATION().then(res => {
                  firebaseService.updateUserOnlineStatus(res.data.id, true)
                  this.$router.push("/overview");
                }).catch(() => {})
              }
            }
          }
        },
          (error) => error
        );
      }
    },
    handleRegisterClick(){
      this.$router.push("/register").then(
        (response) => {},
        (error) => error
      );
    },
    handlePass(event){
      this.passType = false;
    },
  },
};
</script>